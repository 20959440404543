@import "../../import.scss";

.ThreeSixtyView {
}

.info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  background-color: white;
  margin: 24px;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  gap: 24px;
  max-height: 250px;
  min-height: 200px;
  height: 0%;
  .infoImg {
    display: flex;
    flex: 1;
    min-width: 0;
    > img {
      object-fit: cover;
      flex: 1;
      min-width: 0;
    }
  }
  .rest {
    flex: 1;
    min-width: 0;
  }
  .closeButton {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    &:active {
      opacity: 0.8;
    }
  }
}

.backtoapp {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 120%;
  border: 1px solid #999;
  &:active {
    opacity: 0.8;
  }
}
