@import "../../import.scss";

.Co2Result {
}
.spinningEarth{
    margin: 20px;
}

.gameText {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: white;
  margin: 24px;
  border-radius: 12px;
  padding: 12px;
  border: 2px double #444;
  font-family: monospace;
  font-size: 120%;
  .title {
    margin-bottom: 12px;
  }
  .status {
    background-color: #ddd;
    margin-bottom: 14px;
    .statusBar {
      background-color: $green;
      height: 20px;
    }
  }
  .tryAgain{
      font-weight: bold;
  }
}
