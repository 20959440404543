@import "../../import.scss";

.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  &.big {
    height: 100px;
    > img {
      height: 60px;
    }
  }
  > img {
    height: 30px;
    object-fit: contain;
  }
}
