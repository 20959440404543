@import "../../import.scss";

.Button {
  min-height: 40px;
  background-color: $orange;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 120%;
  border-radius: 8px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  &.big {
    min-height: 50px;
  }
  &.green {
    background-color: $green;
  }
  &.rotate {
    margin-top: 12px;
    margin-bottom: 12px;
    transform: rotate(-2deg);
    margin-left: 12px;
    margin-right: 12px;
  }
  &.flex1 {
    flex: 1;
    max-height: none;
  }
  &.score0 {
    background-color: #ddd;
  }
  &:active{
    opacity: 0.8;
  }
}
