@import "../../import.scss";
.forscher {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.questionCard {
  margin-left: 20px;
  margin-right: 20px;
  .inner {
    background-color: $green;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;

    font-weight: bold;
    font-size: 120%;
  }
  .question {
    width: 60%;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.answerButton {
  margin-left: 20px;
  margin-right: 20px;
  background-color: $orange;
  color: white;
  font-weight: bold;
  text-align: center;
  flex: 1;
  //max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.gameCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 8px;
  padding: 12px;
  .title {
    color: $darkText;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .img {
    display: flex;
    flex-direction: column;
    img {
      flex: 1;
      object-fit: cover;
    }
  }
}

.difficultySelector {
  display: flex;
  flex-direction: column;
  margin: 8px;
  flex: 1;
  .top {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 8px;
    padding: 12px;
    .title {
      color: $green;
      font-style: italic;
      font-size: 200%;
    }
    .text {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
  .bottom {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .left {
      flex: 1;
      background-color: white;
      padding: 12px;
      display: flex;
      flex-direction: column;
      min-width: 200px;
      .title {
        margin-top: 12px;
        color: $darkText;
        margin-bottom: 12px;
      }
      .text {
        font-size: 80%;
        margin-bottom: 12px;
      }
      .img {
        display: flex;
        flex-direction: column;
        img {
          flex: 1;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

.questionAnswer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 12px;
  padding: 12px;
  flex: 1;
  .img {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      flex: 1;
      width: 80%;
    }
  }
  .question {
    text-align: center;
    font-weight: bold;
    font-size: 200%;
    color: $darkText;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 12px;
  }
  .questionNumber {
    color: $green;
    margin-top: 12px;
    text-align: center;
    font-weight: bold;
    font-style: italic;
  }
  .answers {
    font-size: 120%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .flex {
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
