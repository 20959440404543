@import "./import.scss";

.flex1 {
  flex: 1;
}

.flex {
  display: flex;
}

.card {
  background-color: white;
  border-radius: 20px;
  padding: 14px;
  box-shadow: 2px 2px 6px 1px #00000044;
  .inner {
    border-radius: 10px;
    background-color: $background;
  }
}

.italic {
  font-style: italic;
}

.center {
  text-align: center;
}

.shadow {
  box-shadow: 3px 3px 6px 0px #00000033;
}

.title {
  font-weight: bold;
  font-size: 120%;
  &.big{
    text-align: center;
    font-weight: bold;
    font-size: 200%;
    color: $darkText;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.padding {
  padding: 20px;
}
