@import "../../import.scss";

.CharacterSelector {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px;
  .character {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    &.active {
      background-color: $green;
    }
    img {
      width: 130px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .name {
    font-weight: bold;
    margin-bottom: 24px;
  }
}
