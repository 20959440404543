@import "../../import.scss";

.Turm {
}
.panoControls {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
  .controlsButton,
  button {
    all: unset;
    background-color: $green;
    color: white;
    padding: 8px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: darken($green, 5%);
    }
  }
  .settingsList {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.panolens-container {
  overflow: hidden;
}
.turmOverlay {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #00000077;
  .inner {
    margin: 24px;
    zoom: 0.7;
    //responsive after 700 height
    @media (max-height: 700px) {
      zoom: 0.5;
    }
  }
  .closeOverlay {
    margin-bottom: 80px;
    height: 40px;
    background-color: $green;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-top: 12px;
    font-weight: bold;
    @media (max-height: 700px) {
      margin-bottom: 0;
    }
  }
}

.turmLevelIndicator {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  justify-content: center;
  img {
    height: 200px;
    pointer-events: none;
    touch-action: none;
  }
  .currentLevel {
    width: 80px;
    height: 10px;
    color: greenyellow;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    touch-action: none;
    position: absolute;
  }
  .levels {
    position: absolute;
    top: 0;
    color: greenyellow;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
