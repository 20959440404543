@import "../../import.scss";

.Nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000077;
  display: flex;
  flex-direction: column;
  z-index: 100;

  .navHeader {
    min-height: 80px;
    max-height: 80px;
    background-color: $darkGrey;
    .logo {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex: 1;
      min-width: 0px;
      padding-left: 12px;
      img {
        margin-top: 20px;
        flex: 1;
        min-width: 0px;
        object-fit: contain;
        object-position: 0% 100%;
      }
    }
  }
  .navInner {
    flex: 1;
    margin-right: 80px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-right: 12px;
    padding-left: 24px;
    max-width: 300px;
    overflow-y: auto;
    min-height: 0;
    .navWrap {
      padding-top: 12px;
      padding-bottom: 12px;
      min-height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .cardBoost {
      flex: 1;
      .text {
        font-size: 80%;
        margin-right: 20px;
      }
    }
    .navFooter {
      .impressum {
        font-size: 90%;
      }
      .credits {
        font-size: 80%;
      }
    }
  }
  .closeNav {
    background-color: $darkGrey;
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 200%;
  }
  .navItem {
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
}
