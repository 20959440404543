@import "../../import.scss";

.apps {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 8px;
  gap: 12px;
  font-size: 100%;

  flex-wrap: wrap;
  .app {
    flex: 1;
    background-color: white;
    padding: 12px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    .title {
      color: $darkText;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .img {
      display: flex;
      flex-direction: column;

      img {
        object-fit: contain;
        flex: 1;
        width: 100%;
      }
    }
  }
  .text {
    margin-bottom: 24px;
  }
}
.introText {
  padding: 12px;
  margin: 8px;
  background-color: white;
  margin-bottom: 0px;
  .title {
    margin-bottom: 12px;
    font-size: 130%;
  }
}
