@import "../../import.scss";

.scrollBox {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .holder {
    max-height: 60px;
    transition: max-height 1s, padding 1s;
    &.open {
      max-height: 1500px;
    }

    &:last-child {
      max-height: 1500px;
    }
  }
}
