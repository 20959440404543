@import "../../import.scss";
.co2 {
  background-color: #28343d;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  .room {
    flex-direction: column;
    width: 80%;
    height: 80vw;
    position: relative;
    margin: 10%;

    display: flex;
    flex: 1;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .gameText {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: white;
    margin: 24px;
    border-radius: 12px;
    padding: 12px;
    border: 2px double #444;
    font-family: monospace;
    font-size: 120%;
    .title {
      margin-bottom: 12px;
    }
  }

  .pfeil {
    position: absolute;
    z-index: 12;
    &.pu {
      left: 50%;
      top: -5%;
    }
    &.pl {
      left: -5%;
      top: 35%;
    }
    &.pr {
      right: -5%;
      top: 35%;
    }
    &.pd {
      bottom: -5%;
      left: 50%;
    }
    img {
      width: 10vw;
    }
  }
  .inGameCharacter {
    position: absolute;
    top: 40%;
    width: 0;
    height: 0;
    img {
      width: 13vw;

      transform: translate(-50%, -50%);
    }
  }
  @keyframes leaves {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.08);
    }
  }
  .itemWrapper {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 14;
    animation: leaves 1s ease-in-out infinite alternate;
    -webkit-animation: leaves 1s ease-in-out infinite alternate;
    .itemImg {
      width: 10vw;
      position: relative;

      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }
  .questionPopup {
    position: fixed;
    bottom: 0px;
    right: 0px;

    background-color: white;
    margin: 24px;
    border-radius: 12px;
    padding: 12px;
    border: 2px double #444;
    font-family: monospace;
    font-size: 100%;
    max-width: 280px;
    z-index: 200;
    .itemOption {
      margin-bottom: 12px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.selected {
        color: red;
      }
    }
  }
  .doneButton {
    font-weight: bold;
    text-decoration: underline;
    text-align: right;
  }
}
