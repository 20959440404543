@import "../../import.scss";

.ActionLink {
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    opacity: 0.8;
  }
}
