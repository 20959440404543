@import "../../import.scss";

.kambodschaOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #000000aa;
  z-index: 20;
  .image {
    flex: 1;
    display: flex;
    overflow: hidden;
    img {
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .youtube {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
      max-width: 600px;
      max-height: 500px;
    }
  }
  .text {
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 24px;
  }
}
